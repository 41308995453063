import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Spacer from "./Spacer";
import NewBtn from "./NewBtn";
import Sensors from "./sensors/Sensors";

const Container = styled.div`
  width: auto;
  margin-left: 16rem;
  position: relative;
  padding: 0 4rem;
`;



const Sensorer = () => {
  const [aktivSensors, setAktivSensors] = useState([]);
  const [reperasjonSensors, setReperasjonsSensors] = useState([]);
  const [paLagerSensors, setPaLagerSensors] = useState([]);

  let navigate = useNavigate();

  function onHandleClick(href) {
    navigate(href);
    }

  useEffect(() => {
    const allAktivSensorsResp = async () => {
      await axios
        .get("https://api.havardo.no/sensors/aktiv", {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          //console.log(response.data)
          //console.log(response.data.username)
          setAktivSensors(response.data.sensors);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    allAktivSensorsResp();
  }, []);

  useEffect(() => {
    const allRepSensorsResp = async () => {
      await axios
        .get("https://api.havardo.no/sensors/reparasjon", {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          //console.log(response.data)
          //console.log(response.data.username)
          setReperasjonsSensors(response.data.sensors);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    allRepSensorsResp();
  }, []);

  useEffect(() => {
    const allPaLagerSensors = async () => {
      await axios
        .get("https://api.havardo.no/sensors/palager", {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          //console.log(response.data)
          //console.log(response.data.username)
          setPaLagerSensors(response.data.sensors);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    allPaLagerSensors();
  }, []);

  var keyCount = aktivSensors.length;
  var keyCount2 = reperasjonSensors.length;
  var keyCount3 = paLagerSensors.length;

  var aktivCount = 0;
  var lagerCount = 0;
  var repCount = 0;

  if(aktivSensors.length === 1 && aktivSensors[0].eui === "N/A") {
    aktivCount = 0;
  } else {
    aktivCount = keyCount;
  }

  if(reperasjonSensors.length === 1 && reperasjonSensors[0].eui === "N/A") {
    repCount = 0;
  } else {
    repCount = keyCount2;
  }

  if(paLagerSensors.length === 1 && paLagerSensors[0].eui === "N/A") {
    lagerCount = 0;
  } else {  
    lagerCount = keyCount3;
  }

  return (
    <Container>
      <Spacer />
      <NewBtn onClick={() => onHandleClick("/sensorer/ny/")} />
      <Sensors 
        data={aktivSensors}
        title="Aktive sensorer"
        count={aktivCount}
        showCount={true}
      />
      <Sensors
        title="Sensorer inne for reperasjon"
        count={repCount}
        data={reperasjonSensors}
        showCount={true}
      />
      <Sensors
        title="Sensorer inne på lager"
        count={lagerCount}
        data={paLagerSensors}
        showCount={true}
      />
    </Container>
  );
};

export default Sensorer;
