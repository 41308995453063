import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5rem;
  width: 100%;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textColor};
  border: none;
  padding: 0.8rem;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.secondary}
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

const PageCount = styled.span`
  margin-left: 0.5rem;
  margin-top: 0.9rem;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.textColor};
  text-align: center;
`;

const Strong = styled.strong``;

const Pagination = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  pageIndex,
}) => {
  return (
    <Container>
      <ButtonWrapper>
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>{" "}
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </Button>{" "}
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </Button>{" "}
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </Button>{" "}
      </ButtonWrapper>
      <PageCount>
        Side{" "}
        <Strong>
          {pageIndex + 1} av {pageOptions.length}
        </Strong>{" "}
      </PageCount>
    </Container>
  );
};

export default Pagination;
