import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import "typeface-roboto";
import "./Main.css";
import Sensors from "./sensors/Sensors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  width: auto;
  margin-left: 16rem;
  padding: 0 4rem;
  min-width: 1080px;
  max-height: 100vh;
`;

const SensorContainer = styled.div`
  width: auto;
  margin-top: 5rem;
  margin-left: -4rem;
  padding: 0 4rem;
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: bold;
  font-family: "Bebas Neue";
  height: 32px;
`;

const SubHeader = styled.h2`
  font-size: 24px;
  font-weight: bold;
  font-family: "Bebas Neue";
`;

const InfoTextBox = styled.div`
  display: flex;
  background-color: #0077a2;
  font-size: 16px;
  font-weight: bold;
  font-family: "Bebas Neue";
  min-width: 200px;
  width: 250px;
  height: 50px;
  border-radius: 120px;
  float: left;
  margin-right: 10px;
`;

const Icons = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-content: center;
  color: #fff;
`;

const Text = styled.p`
  font-size: 17px;
  font-family: "Roboto";
  display: flex;
  margin-top: 0.925rem;
  margin-left: 1px;
  margin-right: 5px;
  align-content: center;
  color: #fff;
`;

const Sum = styled.p`
  margin-top: 0.78rem;
  margin-left: 10px;
  margin-right: 5px;
  color: #fff;
  font-size: 22px;
  font-family: "Roboto";
  display: flex;
  margin-left: 25px;
`;

function Main() {
  const [allSensors, setAllSensors] = useState([]);
  const [aktivCount, setAktivCount] = useState([]);
  const [reparasjonCount, setReparasjonCount] = useState([]);
  const [lagerCount, setLagerCount] = useState([]);


  useEffect(() => {
    const aktivCountResp = async () => {
      await axios
        .get("https://api.havardo.no/sensors/amount/", {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          setAktivCount(response.data.aktiv);
          setLagerCount(response.data.pa_lager);
          setReparasjonCount(response.data.til_reparasjon);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    aktivCountResp();
  }, []);

  useEffect(() => {
    const sensorsResp = async () => {
      await axios
        .get("https://api.havardo.no/sensors/orderedByUpdated", {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          setAllSensors(response.data.sensors);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    sensorsResp();
  }, []);

  return (
    <Container>
      <Header> Sensoroversikt </Header>
      <SubHeader> Oversikt over alle sensorer og lokasjoner </SubHeader>
      <InfoTextBox>
        <Icons>
          <FontAwesomeIcon icon="fa-solid fa-square-check" />
        </Icons>
        <Text>Aktive sensorer</Text>
        <Sum>{aktivCount}</Sum>
      </InfoTextBox>
      <InfoTextBox>
        <Icons>
          <FontAwesomeIcon icon="fa-solid fa-screwdriver-wrench" />
        </Icons>
        <Text>Sensorer til rep.</Text>
        <Sum>{reparasjonCount}</Sum>
      </InfoTextBox>
      <InfoTextBox>
        <Icons>
          <FontAwesomeIcon icon="fa-solid fa-warehouse" />
        </Icons>
        <Text>Sensorer på lager</Text>
        <Sum>{lagerCount}</Sum>
      </InfoTextBox>
      <SensorContainer>
        <Sensors title="Sensorer" data={allSensors} showButton="true" addSensor="true" goto="/sensorer/alle/" newLink="/sensorer/ny/" />
      </SensorContainer>
    </Container>
  );
}

export default Main;
