import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import NewLocation from "../../components/main/lokasjoner/newLocation";

export default function NySensor() {
  return (
    <div>
      <Sidebar />
      <NewLocation/>
    </div>
  );
}
