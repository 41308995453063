import React from "react";
import { Navigate, useLocation } from "react-router-dom";

function PublicRoute({ children }) {
  let location = useLocation();

  if (localStorage.getItem("accessToken")) {
    return <Navigate to="/" state={{ from: location }} />;
  } else {
    return children;
  }
}

export default PublicRoute;
