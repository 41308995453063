import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    padding: 1rem;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2rem;
`

const Spacer = () => {
    return (
        <Container>
            
        </Container>
    )
}

export default Spacer