import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import "./Register.css";
import logo from "../../images/banner.png";

export default function Register() {
  const [usernameRef, setUsernameRef] = useState();
  const [passwordRef, setPasswordRef] = useState();
  const [emailRef, setEmailRef] = useState();
  const [confirmPasswordRef, setConfirmPasswordRef] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    if (
      !usernameRef ||
      !passwordRef ||
      !confirmPasswordRef ||
      !emailRef
    ) {
      setError("Alle feltene må fylles ut");
      return;
    }

    if (passwordRef !== confirmPasswordRef) {
      setError("Passordene må være like");
      return;
    }

    Axios.post("https://api.havardo.no/auth/register", {
      username: usernameRef,
      password: passwordRef,
      email: emailRef,
    }).then((res) => {
      console.log(res);
      setError(res.data.message);
    })

    navigate("/login");
  };

  return (
    <main className="login">
      <div className="wrapper">
        <div className="back-button">
          <a href="https://sandefjord.kommune.no">
            <i>
              <FontAwesomeIcon icon={["fas", "arrow-left"]} />
            </i>
            <span>Tilbake til hovedsiden</span>
          </a>
        </div>

        <div className="center">
          <div className="logo-banner">
            <img src={logo} alt="Kommune logo" />
          </div>
          <div className="login-form">
            {error && <p className="error">{error}</p>}
            <form>
              <div className="input-group">
                <label htmlFor="username">Brukernavn</label>
                <input
                  type="username"
                  id="username"
                  onChange={(e) => {
                    if (loading === true) {
                      setLoading(false);
                    }
                    setUsernameRef(e.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">E-post</label>
                <input
                  type="email"
                  id="email"
                  onChange={(e) => {
                    if (loading === true) {
                      setLoading(false);
                    }
                    setEmailRef(e.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Passord</label>
                <input
                  type="password"
                  id="password"
                  onChange={(e) => {
                    if (loading === true) {
                      setLoading(false);
                    }
                    setPasswordRef(e.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Bekreft passord</label>
                <input
                  type="password"
                  id="confirmpassword"
                  onChange={(e) => {
                    if (loading === true) {
                      setLoading(false);
                    }
                    setConfirmPasswordRef(e.target.value);
                  }}
                />
              </div>
              <button disabled={loading} className="btn" type="submit" onClick={handleSubmit}>
                Registrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
