import React from "react";
import styled from "styled-components";
import AllSensorBtn from "../sensors/AllSensorBtn";
import NewBtn from "../NewBtn";
import { useNavigate } from "react-router-dom";
import LocationTable from "./LocationTable";

const Container = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`;

const Title = styled.h1`
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
  font-size: 1.3rem;
  display: flex;
  align-items: center;
`;

const Lokasjoner = ({ title, data, showButton, addLocation, goto }) => {
  const navigate = useNavigate();

  function handleClick(href) {
    navigate(href);
  }

  return (
    <Container>
      <Title>
        {title}
        {showButton && <AllSensorBtn title={title} onClick={() => handleClick(goto)} />}
        {addLocation && <NewBtn onClick={() => handleClick(goto)} />}
      </Title>
        <LocationTable data={data}/>
    </Container>
  );
};

export default Lokasjoner;
