import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import Spacer from "./Spacer";
import Lokasjoner from "./lokasjoner/Locations";

const Container = styled.div`
  width: auto;
  margin-left: 16rem;
  position: relative;
  padding: 0 4rem;
`;

const Locations = () => {
  const [allLocations, setAllLocations] = useState([]);

  const fetchLocations = async () => {
    await axios
      .get("https://api.havardo.no/locations", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        setAllLocations(response.data.locations);
      })
      .catch((error) => {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      });
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const keyCount = 0;

  const lokasjonCount = keyCount;

  return (
    <Container>
      <Spacer />
      <Lokasjoner
        title="Lokasjoner"
        count={lokasjonCount}
        data={allLocations}
        addLocation={"true"}
        goto="/lokasjoner/ny"
      />
    </Container>
  );
};

export default Locations;
