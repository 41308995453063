import React from "react";
import styled from "styled-components";
import AllSensorBtn from "./AllSensorBtn";
import NewBtn from "../NewBtn";
import { useNavigate } from "react-router-dom";
import SensorTable from "./SensorTable";

const Container = styled.div`
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  height: 4rem;
`;

const SensorsCount = styled.div`
  margin-left: 1rem;
  font-size: 1rem;
  background-color: ${({ theme }) => theme.header};
  color: ${({ theme }) => theme.headerNumber};
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
`;

const Sensors = ({ title, data, count, showCount, showButton, addSensor, goto, newLink}) => {
  const navigate = useNavigate();

  function handleClick(href) {
    navigate(href);
  }

  return (
    <Container>
      <Title>
        {title}
        {showCount && <SensorsCount>{count}</SensorsCount>}
        {showButton && <AllSensorBtn title={title} onClick={() => handleClick(goto)} />}
        {addSensor && <NewBtn onClick={() => handleClick(newLink)} />}
      </Title>
      <SensorTable data={data} />
    </Container>
  );
};

export default Sensors;
