import React, { useEffect } from "react";
import styled from "styled-components";
import UneditableFields from "./utils/Fields";
import axios from "axios";

const Container = styled.div`
  width: auto;
  margin-left: 16rem;
  padding: 0 4rem;
  min-width: 1080px;
  max-height: 100vh;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colorGreen};
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 80px;
  border-radius: 25px;
  position: absolute;
  right: 5rem;
  font-size: 16px;
  cursor: pointer;
  color: white;
  &:hover {
    box-shadow: 0 0 0 2px rgba(117, 194, 130, 0.6);
  }
`;

const Edit = styled.button`
  background-color: ${({ theme }) => theme.colorGreen};
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 80px;
  border-radius: 25px;
  position: absolute;
  right: 5rem;
  font-size: 16px;
  cursor: pointer;
  color: white;
  &:hover {
    box-shadow: 0 0 0 2px rgba(117, 194, 130, 0.6);
  }
`;

const Grid = styled.div`
  max-width: 800px;
  max-height: 600px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 2rem;
`;

const Profil = () => {
  const [user, setUser] = React.useState([]);
  const [editable, setEditable] = React.useState(false);
  
  const url = "https://api.havardo.no";

  const userid = localStorage.getItem("userid");

  useEffect(() => {
    const getUserInfo = async () => {
      await axios
        .get(url + "/user/" + userid, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
            username: localStorage.getItem("username"),
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    getUserInfo();
  }, [url, userid]);

  function updateUser() {
    const fornavn = document.getElementById("fornavn").value;
    const etternavn = document.getElementById("etternavn").value;
    const telefon = document.getElementById("telefon").value;
    const epost = document.getElementById("epost").value;
    const userId = localStorage.getItem("userid"); // Fetch the user ID from local storage
  
    const url = `https://api.havardo.no`; // Update the URL with the user ID
  
    const requestData = { // Create an object with the updated user data
      fornavn: fornavn,
      etternavn: etternavn,
      telefon: telefon,
      epost: epost,
    };
  
    axios
      .put(url + "/user/update/" + userId, requestData, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
          username: localStorage.getItem("username"),
        },
      })
      .then((response) => {
        console.log(response.data); // Log the response data
        setEditable(false); // Set editable to false to disable the fields
        // Perform any additional actions on successful update
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data); // Log the error response data
          console.log(error.response.status); // Log the error response status
          console.log(error.response.headers); // Log the error response headers
        }
        // Perform any error handling or display error messages to the user
      });
  }
  

  const allowEdit = () => {
    if (editable === false) {
      setEditable(true);
      console.log("Enabled editing")
    } else {
      updateUser();
    }
  };

  function checkKommune(value) {
    return value === 1
      ? "Sandefjord"
      : value === 2
      ? "Tønsberg"
      : "Ingen kommune";
  }

  return (
    <Container>
      <h1>Profil</h1>
      <h2>Her kan du endre dine personlige opplysninger</h2>
      <Grid>
        <UneditableFields
          editable={editable}
          id="fornavn"
          value={user.fornavn}
          handleChange={(e) => setUser({ ...user, fornavn: e.target.value })}
          header="Fornavn"
        />
        <UneditableFields
          editable={editable}
          id="etternavn"
          value={user.etternavn}
          handleChange={(e) => setUser({ ...user, etternavn: e.target.value })}
          header="Etternavn"
        />
        <UneditableFields
          editable={editable}
          id="telefon"
          value={user.telefon}
          handleChange={(e) => setUser({ ...user, telefon: e.target.value })}
          header="Telefon"
        />
        <UneditableFields 
          editable={editable} 
          id="epost"
          value={user.epost} 
          handleChange={(e) => setUser({ ...user, epost: e.target.value })}
          header="E-post" 
        />
        <UneditableFields
          editable={false}
          value={checkKommune(user.kommune)}
          header="Kommune"
        />
        <UneditableFields
          editable={false}
          value={user.regtid}
          header="Tid registrert"
        />
      </Grid>
      {editable ? (
        <Edit onClick={() => allowEdit()}>Lagre endringer</Edit>
      ) : (
        <Button onClick={() => allowEdit()}>Gjør endringer</Button>
      )}
    </Container>
  );
};

export default Profil;
