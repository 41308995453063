import React, { useState, useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

function PrivateRoute({ children }) {
  let location = useLocation();

  if (!localStorage.getItem("accessToken")) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else {
    return children;
  }
}

export default PrivateRoute;
