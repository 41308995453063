import React, { useEffect } from "react";
import styled from "styled-components";
import Image from "../../images/avatar.jpg";
import axios from "axios";

const Container = styled.div`
  margin-top: 3rem;
`;

const ProfileImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileImg = styled.img`
  height: 5rem;
  border-radius: 50%;
  object-align: center;
`;
const ProfileName = styled.h1`
  font-size: 1rem;
  font-weight: 300;
  color: ${({ theme }) => theme.textColor};
  text-align: center;
`;

const Profile = () => {
  const [fornavn, setFornavn] = React.useState("");
  const [etternavn, setEtternavn] = React.useState("");
  const url = "https://api.havardo.no/user/" + localStorage.getItem("userid");

  useEffect(() => {
    const getUserInfo = async () => {
      await axios
        .get(url, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          setFornavn(response.data.fornavn);
          setEtternavn(response.data.etternavn);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    getUserInfo();
  }, [url]);

  return (
    <Container>
      <ProfileImage>
        <ProfileImg src={Image} />
      </ProfileImage>

      <ProfileName>
        {fornavn} {etternavn}
      </ProfileName>
    </Container>
  );
};

export default Profile;
