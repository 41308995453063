import React from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import VisAlleSensorer from "../../components/main/VisAlleSensorer";

export default function Sensor() {
  return (
    <div>
      <Sidebar />
      <VisAlleSensorer />
    </div>
  );
}
