/* eslint-disable no-lone-blocks */
import React from "react";
import { useNavigate } from "react-router-dom";
import MenuLink from "./MenuLink";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const Container = styled.div`
  margin-top: 2rem;
  margin-right: 7.4rem;
  width: 100%;
`;

const Menu = () => {

  let navigate = useNavigate();
  const authState = localStorage.getItem("role");
  const userGroup = authState;

  let dashActive,
  sensorsActive,
  locationsActive,
  adminActive,
  settingsActive = false;

  const currentUrl = window.location.pathname;


  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userid");
    navigate("/login");
  };
  
  const onHandleClick = (href) => {
      navigate(href);
  };
  
  {(() => {
  switch (currentUrl) {
    /* SENSORER */
    case "/sensorer":
    case "/sensorer/alle/":
    case "/sensorer/ny/":
    sensorsActive = true;
    return;
    /* LOKASJONER */
    case "/lokasjoner":
    case "/lokasjoner/ny":
      locationsActive = true; 
      return;
    /* PROFIL */
    case "/profil":
      settingsActive = true;
      return;
    /* ADMIN */
    case "/admin":
      adminActive = true;
      return;
    /* DASHBOARD */
    default:
      dashActive = true;
      return;
  }
})()}

  return (
    <div>
    <Container>
      <MenuLink title="Home" active={dashActive} onClick={ () => onHandleClick("/")}>
        <FontAwesomeIcon icon="fa-solid fa-house" />
      </MenuLink>
      <MenuLink title="Sensorer" active={sensorsActive} onClick={ () => onHandleClick("/sensorer")}>
        <FontAwesomeIcon icon="fa-solid fa-temperature-full" />
      </MenuLink>
      <MenuLink title="Lokasjoner" active={locationsActive} onClick={ () => onHandleClick("/lokasjoner")}>
        <FontAwesomeIcon icon="fa-solid fa-location-dot" />
      </MenuLink>
      <MenuLink title="Profil" active={settingsActive} onClick={ () => onHandleClick("/profil")}>
        <FontAwesomeIcon icon="fa-solid fa-user-gear" />
      </MenuLink>
      <MenuLink title="Logg ut" onClick={logout}>
        <FontAwesomeIcon icon="fa-solid fa-door-open" />
      </MenuLink>
      {userGroup === "admin" && (
        <MenuLink title="Admin" active={adminActive} onClick={ () => onHandleClick("/admin")}>
          <FontAwesomeIcon icon="fa-solid fa-crown" />
        </MenuLink>
      )}
    </Container>
    </div>
  );
};

export default Menu;
