import React from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import LocationInformation from "../../components/main/lokasjoner/LocationInformation";

export default function SensorInfo() {

  return (
    <div>
      <Sidebar />
      <LocationInformation/>
    </div>
  );
}