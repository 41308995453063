import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Profil from '../../components/main/profil/Profil'
  

function Profile() {
  return (
    <div>
      <Sidebar />
      <Profil />
    </div>
  )
}

export default Profile