import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  display: flex;
  margin-top: 2.5rem;
`;

const BackButton = styled.a`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: absolute;
  top: 2rem;
  left: 2rem;
  cursor: pointer;
  transition: all ease-in-out 300ms;
  color: #fff;
  border: 2px solid #fff;

  &:hover {
    box-shadow: 0 0 0 2px rgba(117, 194, 130, 0.6);
  }
`;

const AddIcon = styled.span`
  color: #ffffff;
  font-size: 36px;
`;

const GoBackButton = ({onClick}) => {

    return (
  <Container>
    <BackButton onClick={onClick}>
      <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
    </BackButton>
  </Container>
)};

export default GoBackButton;
