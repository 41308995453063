import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/app/App';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faDoorOpen, faHome, faLocationDot, faPlus, faTemperatureFull, faUserCog, faUsersCog, faSquareCheck, faSkull, faScrewdriverWrench, faWarehouse, faCrown} from '@fortawesome/free-solid-svg-icons'

library.add(faArrowLeft, faPlus, faHome, faLocationDot, faTemperatureFull, faDoorOpen, faUserCog, faUsersCog, faSquareCheck, faSkull, faScrewdriverWrench, faWarehouse, faCrown, faArrowLeft)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

