import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import "./Login.css";
import logo from "../../images/banner.png";


function Login() {
  const [usernameRef, setUsernameRef] = useState();
  const [passwordRef, setPasswordRef] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!usernameRef ) {
      setError("Brukernavn må fylles ut");
      return;
    }
    if(!passwordRef) {
      setError("Passord må fylles ut");
      return;
    }

    Axios.post("https://api.havardo.no/auth/login", {
      username: usernameRef,
      password: passwordRef,
    }).then((res) => {
      if(res.data.message) {
        setError(res.data.message);
        setLoading(false);
      } else {

        localStorage.setItem("accessToken", res.data.token);
        localStorage.setItem("userid", res.data.id);
        localStorage.setItem("role", res.data.role);
        localStorage.setItem("username", res.data.username);
        navigate("/");
      }
    });
  };

  return (
    <main className="login">
      <div className="wrapper">
        <div className="back-button">
          <a href="https://sandefjord.kommune.no">
            <i>
              <FontAwesomeIcon icon={["fas", "arrow-left"]} />
            </i>
            <span>Tilbake til hovedsiden</span>
          </a>
        </div>

        <div className="center">
          <div className="logo-banner">
            <img src={logo} alt="Kommune logo" />
          </div>
          <div className="login-form">
            {error && <p className="error">{error}</p>}
            <form>
              <div className="input-group">
                <label htmlFor="username">Brukernavn</label>
                <input
                  type="username"
                  id="username"
                  onChange={(e) => {
                    if (loading === true) {
                      setLoading(false);
                    }
                    setUsernameRef(e.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Passord</label>
                <input
                  type="password"
                  id="password"
                  onChange={(e) => {
                    if (loading === true) {
                      setLoading(false);
                    }
                    setPasswordRef(e.target.value);
                  }}
                />
              </div>
              <button
                disabled={loading}
                className="btn"
                type="submit"
                onClick={handleSubmit}
              >
                Logg inn
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
