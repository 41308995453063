import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddButton = styled.button`
    background-color: ${({ theme }) => theme.colorGreen};
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: absolute;
    top: 2rem;
    right: 5rem;
    cursor: pointer;
    transition: all ease-in-out 300ms;
    color: #fff;
    margin-top: -2.5rem;

    &:hover {
        box-shadow: 0 0 0 2px rgba(117, 194, 130, 0.6);
    }

`

const AddButtons = styled.button`
    background-color: ${({ theme }) => theme.colorGreen};
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: absolute;
    right: 5rem;
    cursor: pointer;
    transition: all ease-in-out 300ms;
    color: #fff;
    margin-top: -1rem;

    &:hover {
        box-shadow: 0 0 0 2px rgba(117, 194, 130, 0.6);
    }

`

const AddIcon = styled.span`
    color: #ffffff;
    font-size: 36px;
`


const NewSensorBtn = ({onClick}) => {

    return (
    <AddButtons onClick={onClick}>
        <FontAwesomeIcon icon="fa-solid fa-plus" />
    </AddButtons>
    )}



export default NewSensorBtn