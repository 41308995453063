import React from 'react';
import styled from 'styled-components';

const Div = styled.div `
`;

const Input = styled.input`
  height: 3rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  width: 220px;
`;

const Header = styled.h1`
  font-size: 18px;
  font-weight: bold;
`;



const UneditableFields = ({ editable, value, handleChange, header, id }) => {


  return (
    <Div>
      <Header>{header}</Header>
      <Input type="text" value={value} readOnly={!editable} id={id} onChange={editable ? handleChange : null} style={!editable ? {color: 'white', border: '1px solid black', background: 'rgba(205, 205, 205, 0.2)'} : {color: 'black', border: '1px solid gray', background: 'white'}} />
      </Div>
  );
};

export default UneditableFields;