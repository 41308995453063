import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import Login from "../login/Login";
import Dashboard from "../dashboard/Dashboard";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import PrivateRoute from "../../routes/PrivateRoute";
import PublicRoute from "../../routes/PublicRoute";
import Profile from "../profile/Profile";
import Register from "../register/Register";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../../styles/global";
import { darkTheme } from "../../styles/theme";
import Sensor from "../sensorer/Sensor";
import AlleSensorer from "../sensorer/AlleSensorer";
import NySensor from "../sensorer/NySensor";
import Lokasjoner from "../lokasjoner/Lokasjoner";
import NyLokasjon from "../lokasjoner/NyLokasjon";
import SensorInfo from "../sensorer/SensorInfo";
import LokasjonInfo from "../lokasjoner/LokasjonInfo";

function App() {
  const [authState, setAuthState] = useState({
    username: "",
    id: 0,
    status: false,
    role: "",
  });

  useEffect(() => {
    if (authState.status === true) {
      axios
        .get("https://api.havardo.no/auth/auth", {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          setAuthState({
            username: response.data.username,
            id: response.data.id,
            role: response.data.role,
            status: true,
          });
          localStorage.clear("accessToken");
          localStorage.setItem("accessToken", response.data.token);
          localStorage.setItem("userid", response.data.id);
          localStorage.setItem("role", response.data.role);
          localStorage.setItem("username", response.data.username);
          console.log(response.data)
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              setAuthState({ ...authState, status: false });
              localStorage.clear("accessToken");
              localStorage.clear("userid");
              localStorage.clear("role");
              localStorage.clear("username");
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            Navigate("/login");
            return;
          }
        });
    }
  }, [authState]);

  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Routes>
          {/* DASHBOARD */}
          <Route
            exact
            path="/"
            element={
              <ThemeProvider theme={darkTheme}>
                <PrivateRoute>
                  <GlobalStyles />
                  <Dashboard />
                </PrivateRoute>
              </ThemeProvider>
            }
          />
          {/* PROFIL */}
          <Route
            path="/profil"
            element={
              <ThemeProvider theme={darkTheme}>
                <PrivateRoute>
                  <GlobalStyles />
                  <Profile />
                </PrivateRoute>
              </ThemeProvider>
            }
          />
          {/* SENSORER */}
          <Route
            path="/sensorer"
            element={
              <ThemeProvider theme={darkTheme}>
                <PrivateRoute>
                  <GlobalStyles />
                  <Sensor />
                </PrivateRoute>
              </ThemeProvider>
            }
          />
          <Route
            path="/sensorer/alle"
            element={
              <ThemeProvider theme={darkTheme}>
                <PrivateRoute>
                  <GlobalStyles />
                  <AlleSensorer />
                </PrivateRoute>
              </ThemeProvider>
            }
          />
          <Route
            path="/sensorer/ny"
            element={
              <ThemeProvider theme={darkTheme}>
                <PrivateRoute>
                  <GlobalStyles />
                  <NySensor />
                </PrivateRoute>
              </ThemeProvider>
            }
          />
          <Route
            path="/sensor/:sensorId"
            element={
              <ThemeProvider theme={darkTheme}>
                <PrivateRoute>
                  <GlobalStyles />
                  <SensorInfo />
                </PrivateRoute>
              </ThemeProvider>
            }
          />
          {/* LOKASJONER */}
          <Route
            path="/lokasjoner"
            element={
              <ThemeProvider theme={darkTheme}>
                <PrivateRoute>
                  <GlobalStyles />
                  <Lokasjoner />
                </PrivateRoute>
              </ThemeProvider>
            }
          />
          <Route
            path="/lokasjon/:lokasjonId"
            element={
              <ThemeProvider theme={darkTheme}>
                <PrivateRoute>
                <GlobalStyles />
                  <LokasjonInfo />
                </PrivateRoute>
              </ThemeProvider>
            }
          />
          <Route
            path="/lokasjoner/ny"
            element={
              <ThemeProvider theme={darkTheme}>
                <PrivateRoute>
                  <GlobalStyles />
                  <NyLokasjon />
                </PrivateRoute>
              </ThemeProvider>
            }
          />
          {/* INNLOGGING OG REGISTRERING */}
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
        </Routes>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
