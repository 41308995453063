import React from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import Lokasjon from "../../components/main/Lokasjoner";

export default function Lokasjoner() {
  return (
    <div>
      <Sidebar />
      <Lokasjon />
    </div>
  );
}
