import React from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import NewSensor from "../../components/main/sensors/newSensor";

export default function NySensor() {
  return (
    <div>
      <Sidebar />
      <NewSensor/>
    </div>
  );
}
