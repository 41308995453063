import React from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import Main from "../../components/main/Main";

require('typeface-bebas-neue');

export default function Dashboard() {
  return (
    <div>
      <Sidebar />
      <Main />
    </div>
  );
}
