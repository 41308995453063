import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import Spacer from "./Spacer";
import Sensors from "./sensors/Sensors";
import GoBackButton from "./BackButton";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: auto;
  margin-left: 16rem;
  position: relative;
  padding: 0 4rem;
`;


const Sensorer = () => {
  const [allSensors, setAllSensors] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    const allSensorsResp = async () => {
      await axios
        .get("https://api.havardo.no/sensors/basic", {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          setAllSensors(response.data.sensors);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    allSensorsResp();
  }, []);

  function onHandleClick(href) {
    navigate(href);
    }

 
  var keyCount = allSensors.length;

  const totalCount = keyCount;


  return (
    <Container>
      <Spacer />
      <GoBackButton onClick={() => onHandleClick("/")}/>
      <Sensors 
        title="Alle sensorer" 
        count={totalCount} 
        data={allSensors} 
        showCount="true"
        addSensor="true"
        newLink="/sensorer/ny"
        />
    </Container>
  );
};

export default Sensorer;
