import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader"
import UneditableFields from "../profil/utils/Fields";

const Container = styled.div`
  width: auto;
  margin-left: 16rem;
  min-width: 1080px;
  max-height: 100vh;
`;

const LeftContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CenterContainer = styled.div`
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CenterContainer2 = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Title = styled.h1`
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
  font-size: 2rem;
  display: flex;
  align-items: center;
  height: 4rem;
`;

const ErrorTitle = styled.h1`
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
  font-size: 4rem;
  display: flex;
  align-items: center;
  height: 4rem;
  margin: 0 1rem;
`;

const BackButton = styled.button`
  background-color: ${({ theme }) => theme.header};
  color: ${({ theme }) => theme.headerNumber};
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  margin: 0 1rem;
`;

const Loading = styled.div`
    font-weight: 500;
    color: ${({ theme }) => theme.textColor};
    font-size: 2rem;
    display: flex;
    align-items: center;
    height: 4rem;
    margin: 0 1rem;
`;

const Grid = styled.div`
  max-width: 800px;
  max-height: 600px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 2rem;
`;


const SensorInfo = () => {
  const { sensorId } = useParams();
  return (
    <Container>
      <LeftContainer>
        <Title> Sensor {sensorId} </Title>
        <Grid>
        <UneditableFields header={"EUI"} />
        <UneditableFields header={"Navn"} />
        <UneditableFields header={"Nettype"} />
        <UneditableFields header={"Serial nr."} />
        <UneditableFields header={"Part nr."} />
        <UneditableFields header={"Lorakode"} />
        <UneditableFields header={"Chassis"} />
        <UneditableFields header={"Appkey"} />
        <UneditableFields header={"Sim ID"} />
        <UneditableFields header={"Status"} />
        <UneditableFields header={"Sist oppdatert"} />
        <UneditableFields header={"Aktiveringsdato"} />
        </Grid>
      </LeftContainer>
    </Container>
  );
};

const Error404 = () => {
  const navigate = useNavigate();

  function GoBack(href) {
    navigate(href);
  }

  return (
    <Container>
      <CenterContainer>
        <ErrorTitle> Error 404 </ErrorTitle>
      </CenterContainer>
      <CenterContainer2>
        <Title> Sensor not found </Title>
        <BackButton onClick={() => GoBack("/")}> Back </BackButton>
      </CenterContainer2>
    </Container>
  );
};

function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

const SensorInformation = () => {
  const [loading, isLoading] = useState(true);
  const { sensorId } = useParams();
  const [sensorExists, setSensorExists] = useState(false);
  const color = "#ffffff";

  useEffect(() => {
    const checkIfSensorExists = async () => {
      await axios
        .get("https://api.havardo.no/sensors/checkIfSensorExists/" + sensorId, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          setSensorExists(response.data.exists);
          sleep(300);
          isLoading(false)
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    checkIfSensorExists();
  }, [sensorId]);



  return (
    (loading ? <CenterContainer><Loading><ClipLoader color={color} loading={loading} size={150} data-testid="loader"/>Loading...</Loading></CenterContainer> : 
  (sensorExists ? <SensorInfo /> : <Error404 />))
  );
}

export default SensorInformation;
