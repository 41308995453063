import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import moment from "moment";

const Container = styled.div`
  width: auto;
  margin-left: 16rem;
  padding: 1rem 4rem;
  min-width: 1080px;
  max-height: 100vh;
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: bold;
  font-family: "Bebas Neue";
  height: 32px;
`;

const SubHeader = styled.h2`
  font-size: 24px;
  font-weight: bold;
  font-family: "Bebas Neue";
`;

const Input = styled.input`
  height: 3rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  width: 220px;
`;

const Label = styled.label`
  font-size: 1rem;
  display: block;
  flex-direction: row;
  margin-top: 1rem;
  width: 240px;
`;

const Select = styled.select`
  height: 3rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  width: 220px;
`;

const Dividers = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 2rem;
`;

const SubmitButton = styled.button`
  display: flex;
  background-color: #0077a2;
  color: #fff;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 16px;
  margin: 20px 15px;
  cursor: pointer;
  &:hover {
    background-color: #0077b3;
    border-radius: 8px;
  }
`;

function NewSensor() {
  const [euiRef, setEuiRef] = useState();
  const [nameRef, setNameRef] = useState();
  const [nettypeRef, setNettypeRef] = useState();
  const [serialRef, setSerialRef] = useState();
  const [partRef, setPartRef] = useState();
  const [lorakodeRef, setLorakodeRef] = useState();
  const [chassisRef, setChassisRef] = useState();
  const [appkeyRef, setAppkeyRef] = useState();
  const [simIDRef, setSimIDRef] = useState();
  const [statusRef, setStatusRef] = useState();
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  const handleSubmit = (e) => {
    if(euiRef == null || nameRef == null || nettypeRef == null || serialRef == null || partRef == null || lorakodeRef == null || chassisRef == null || appkeyRef == null || simIDRef== null || statusRef == null){
      alert("Alle felt må fylles ut");
    } else {

    e.preventDefault();
    setIsLoading(true);

    const now = moment()
    const date = new Date(now);


    axios.post("https://api.havardo.no/sensors/new", {
      eui: euiRef,
      navn: nameRef,
      nettype: nettypeRef,
      serialnr: serialRef,
      partnr: partRef,
      lorakode: lorakodeRef,
      chassis: chassisRef,
      appkey: appkeyRef,
      simid: simIDRef,
      status: statusRef,
      onLocation: 0,
      aktiveringsdato: date
    }, {
      headers: {
        "Content-Type": "application/json",
        accessToken: localStorage.getItem("accessToken"),
      }
    }).then((res) => {
      console.log(res.data);
      navigate("/sensorer/sensor/" + res.data.sensor.sensorId);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }

  };

  return (
    <Container>
      <Header>Ny Sensor</Header>
      <SubHeader>Registrer ny sensor</SubHeader>
      <Dividers>
        <Label>
          EUI
          <Input
            type="text"
            placeholder="Skriv EUI her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setEuiRef(e.target.value);
            }}
          />
        </Label>
        <Label>
          Navn
          <Input
            type="text"
            placeholder="Skriv navn her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setNameRef(e.target.value);
            }}
          />
        </Label>
      </Dividers>
      <Dividers>
        <Label htmlFor="nettype">
          Nettype
          <Select
            id="nettype"
            defaultValue={"velg"}
            required
            onChange={(e) => setNettypeRef(e.target.value)}
          >
            <option value="velg" disabled>
              Velg nettype
            </option>
            <option value="NBIOT">NB-IoT</option>
            <option value="LORAWAN">LoRaWAN</option>
            <option value="ANNEN">Annen</option>
          </Select>
        </Label>
        <Label>
          Serial nr.
          <Input
            type="text"
            placeholder="Skriv serialnr. her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setSerialRef(e.target.value);
            }}
          />
        </Label>
        <Label>
          Part nr.
          <Input
            type="number"
            placeholder="Skriv partnr. her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setPartRef(e.target.value);
            }}
          />
        </Label>
      </Dividers>
      <Dividers>
        <Label>
          Lorakode
          <Input
            type="number"
            placeholder="Skriv lorakode her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setLorakodeRef(e.target.value);
            }}
          />
        </Label>
        <Label>
          Chassis
          <Input
            type="text"
            placeholder="Skriv chassis her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setChassisRef(e.target.value);
            }}
          />
        </Label>
        <Label>
          Appkey
          <Input
            type="text"
            placeholder="Skriv Appkey her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setAppkeyRef(e.target.value);
            }}
          />
        </Label>
      </Dividers>
      <Dividers>
        <Label>
          Sim ID
          <Input
            type="number"
            placeholder="Skriv sim-id her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setSimIDRef(e.target.value);
            }}
          />
        </Label>
        <Label htmlFor="status">
          Status
          <Select
            id="status"
            defaultValue={"velg"}
            required
            onChange={(e) => setStatusRef(e.target.value)}
          >
            <option value="velg" disabled>
              Velg status
            </option>
            <option value="Aktiv">Aktiv</option>
            <option value="Til reparasjon">Til reparasjon</option>
            <option value="På lager">På lager</option>
          </Select>
        </Label>
      </Dividers>

      <SubmitButton onClick={handleSubmit}>Registrer sensor</SubmitButton>
    </Container>
  );
}

export default NewSensor;
