import React from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import Sensorer from "../../components/main/Sensorer";

export default function Sensor() {
  return (
    <div>
      <Sidebar />
      <Sensorer />
    </div>
  );
}
