import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: auto;
  margin-left: 16rem;
  padding: 1rem 4rem;
  min-width: 1080px;
  max-height: 100vh;
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: bold;
  font-family: "Bebas Neue";
  height: 32px;
`;

const SubHeader = styled.h2`
  font-size: 24px;
  font-weight: bold;
  font-family: "Bebas Neue";
`;

const Input = styled.input`
  height: 3rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  width: 220px;
`;

const BigInput = styled.textarea`
  height: 6rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  width: 700px;
`;

const Label = styled.label`
  font-size: 1rem;
  display: block;
  flex-direction: row;
  margin-top: 1rem;
  width: 240px;
`;

const Select = styled.select`
  height: 3rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  width: 220px;
`;

const Dividers = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 2rem;
`;

const SubmitButton = styled.button`
  display: flex;
  background-color: #0077a2;
  color: #fff;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 16px;
  margin: 20px 15px;
  cursor: pointer;
  &:hover {
    background-color: #0077b3;
    border-radius: 8px;
  }
`;

function NewLocation() {
  const [locationName, setLocationName] = useState("");
  const [locationLat, setLocationLat] = useState(0.0);
  const [locationLong, setLocationLong] = useState(0.0);
  const [locationContact, setLocationContact] = useState("");
  const [locationDesc, setLocationDesc] = useState("");
  const [locationKommuneId, setLocationKommuneId] = useState();

  const [kommuner, setKommuner] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [sensorId, setSensorId] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    const fetchKommuner = async () => {
      await axios
        .get("https://api.havardo.no/kommune/getName", {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          setKommuner(response.data.kommuner);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    fetchKommuner();
  }, []);

  function getSensorIDByName(array, e) {
    const index = array.findIndex((element) => element.navn === e);
    const realIndex = index + 1;
    setSensorId(realIndex);
    console.log(realIndex);
    console.log("Switched to " + e + " with index " + realIndex);
    console.log(sensorId + " is the new sensorId");
  }
  
  function handleKommuneId(array, e) {
    const index = array.findIndex((element) => element.kommune === e);
    const realIndex = index + 1;
    setLocationKommuneId(realIndex);
    console.log(realIndex);
    console.log("Switched to " + e + " with index " + realIndex);
    console.log(locationKommuneId + " is the new locationKommuneId");
  }

  useEffect(() => {
    const fetchSensors = async () => {
      await axios
        .get(
          "https://api.havardo.no/sensors/getNameAndIfNotOnAlreadyLocation",
          {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          }
        )
        .then((response) => {
          setSensors(response.data.sensors);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    };
    fetchSensors();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !locationName ||
      !locationLong ||
      !locationLat ||
      !locationContact ||
      !locationDesc
    ) {
      alert("Alle felt må fylles ut");
      return;
    }

    setIsLoading(true);

    const data = {
      name: locationName,
      sensorId: sensorId,
      kommune: locationKommuneId,
      longitude: locationLong,
      latitude: locationLat,
      contact: locationContact,
      description: locationDesc,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        accessToken: localStorage.getItem("accessToken"),
      },
    };

    axios
      .post("https://api.havardo.no/locations/new", data, config)
      .then((res) => {
        navigate("/lokasjon/" + res.data.loc.lokasjonId);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <Header>Ny Lokasjon</Header>
      <SubHeader>Registrer ny lokasjon</SubHeader>
      <Dividers>
        <Label>
          Navn
          <Input
            type="text"
            placeholder="Skriv navn her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setLocationName(e.target.value);
            }}
          />
        </Label>
        <Label htmlFor="sensor">
          Sensor
          <Select
            id="sensor"
            defaultValue={"velg"}
            onChange={(e) => getSensorIDByName(sensors, e.target.value)}
          >
            <option value="velg" disabled>
              Velg sensor
            </option>
            {sensors.map((value) => (
              <option value={value.id} key={value.id}>
                {value.navn}
              </option>
            ))}
          </Select>
        </Label>
        <Label htmlFor="kommune">
          Kommune
          <Select
            id="kommune"
            defaultValue={"velg"}
            onChange={(e) => handleKommuneId(kommuner, e.target.value)}
          >
            <option value="velg" disabled>
              Velg kommune
            </option>
            {kommuner.map((value) => (
              <option value={value.id} key={value.id}>
                {value.kommune}
              </option>
            ))}
          </Select>
        </Label>
      </Dividers>
      <Dividers>
        <Label>
          Longitude
          <Input
            type="number"
            placeholder="Skriv latitude her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setLocationLong(e.target.value);
            }}
          />
        </Label>
        <Label>
          Latitude
          <Input
            type="number"
            placeholder="Skriv latitude her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setLocationLat(e.target.value);
            }}
          />
        </Label>
        <Label>
          Kontaktperson
          <Input
            type="email"
            placeholder="Skriv kontaktperson sin epost"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setLocationContact(e.target.value);
            }}
          />
        </Label>
      </Dividers>
      <Dividers>
        <Label>
          Beskrivelse
          <BigInput
            type="text"
            placeholder="Skriv beskrivelse her"
            onChange={(e) => {
              if (isLoading === true) {
                setIsLoading(false);
              }
              setLocationDesc(e.target.value);
            }}
          />
        </Label>
      </Dividers>

      <SubmitButton onClick={handleSubmit}>Registrer lokasjon</SubmitButton>
    </Container>
  );
}

export default NewLocation;
