import React, { useMemo } from "react";
import styled from "styled-components";
import { useSortBy, useTable, usePagination } from "react-table";
import Pagination from "../tablecomponents/Pagination";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(190, 190, 190, 0.22);
  background-color: ${({ theme }) => theme.primary};
  transition: all ease-in-out 300ms;
`;

const Table = styled.table`
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0;
`;

const TableHead = styled.thead``;

const TableRow = styled.tr`
  font-size: 0.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
`;

const TableRow2 = styled.tr`
  font-size: 0.6rem;
  font-weight: 500;
  color: #aaa5a5;
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;
  }
`;

const TableHeader = styled.th`
  background-color: #2c2839;
  border-radius: 20px;
  padding: 0.8rem 1rem;
  color: ${({ theme }) => theme.textColor};
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
`;

const TableBody = styled.tbody``;

const TableData = styled.td`
  font-size: 0.8rem;
  font-weight: 800;
  padding: 0.8rem 1rem;
  text-align: center;
`;

const LocationTable = ({data}) => {

  const Data = useMemo(() => [...data], [data]);

  const Columns = useMemo(
    () =>
      data[0]
        ? Object.keys(data[0]).map((key) => {
            return { Header: key, accessor: key };
          })
        : [],
    [data]
  );

  const tableInstance = useTable(
    {
      columns: Columns,
      data: Data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex },
  } = tableInstance;

  const navigate = useNavigate();

  function handleClick(href) {
    console.log(href)
    if(href === "lokasjon/N/A") {
      return;
    }
    navigate(href);
  }

  return (
    <>
      <Container>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeader
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow2
                  {...row.getRowProps()}
                  onClick={() => handleClick("/lokasjon/" + row.original.lokasjonId)}
                >
                  {row.cells.map((cell) => (
                    <TableData {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableData>
                  ))}
                </TableRow2>
              );
            })}
          </TableBody>
        </Table>
      </Container>
      <Pagination
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        pageIndex={pageIndex}
      />
    </>
  );
}

export default LocationTable;
