import React from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import SensorInformation from "../../components/main/sensors/SensorInformation";

export default function SensorInfo() {

  return (
    <div>
      <Sidebar />
      <SensorInformation/>
    </div>
  );
}